import { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.onError(error, info);
  }

  render() {
    const { children, errorComponent } = this.props;

    if (this.state.hasError) {
      return errorComponent;
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  onError: () => {}
};

ErrorBoundary.propTypes = {
  onError: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  errorComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ErrorBoundary;
