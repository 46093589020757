const data = {
  fastcompany: {
    logo:
      '//a.fastcompany.net/image/upload/v1488299125/fc/fastcompany-logo.svg',
    title: 'Fast Company',
    subscribeLink: 'https://www.fastcompany.com/combo',
    newsletterLink: 'https://www.fastcompany.com/newsletters',
    topics: [
      {
        url: '/premium',
        title: 'Premium'
      },
      {
        url: '/co-design',
        title: 'Design'
      },
      {
        url: '/technology',
        title: 'Tech'
      },
      {
        url: '/work-life',
        title: 'Work Life'
      },
      {
        url: '/news',
        title: 'News'
      },
      {
        url: '/impact',
        title: 'Impact'
      },
      {
        url: '/podcasts',
        title: 'Podcasts',
        removeOnScale: true,
        external: true
      },
      {
        url: '/videos',
        title: 'Video'
      },
      {
        url: 'https://events.fastcompany.com/innovationfestival24',
        title: 'INNOVATION FESTIVAL',
        external: true
      }
    ],
    sideNavTopics: [
      {
        url: '/premium',
        title: 'Premium'
      },
      {
        url: '/subscribe',
        title: 'Subscribe'
      },
      {
        url: '/co-design',
        title: 'Design'
      },
      {
        url: '/technology',
        title: 'Tech'
      },
      {
        url: '/work-life',
        title: 'Work Life'
      },
      {
        url: '/news',
        title: 'News'
      },
      {
        url: '/impact',
        title: 'Impact'
      },
      {
        url: '/lists',
        title: 'Lists'
      },
      {
        url: '/section/financing-the-future',
        title: 'Financing the Future'
      },
      {
        url: '/podcasts',
        title: 'Podcasts',
        removeOnScale: true,
        external: true
      },
      {
        url: '/videos',
        title: 'Video'
      }
    ],
    channels: [
      {
        logo: '',
        title: 'FastCo Works',
        items: [
          {
            url: '/fcw/capital-one',
            title: 'Capital One'
          },
          {
            url: '/fcw/ibm',
            title: 'IBM'
          },
          {
            url: '/fcw/sap',
            title: 'SAP'
          }
        ]
      }
    ]
  }
};

export default data;
