import React from 'react';

function DefaultError() {
  return (
    <div className="error-boundary">
      <div className="error-boundary__message">
        <p>Uh-oh! We don&rsquo;t know what happened here.</p>
        <a href="mailto:e8g2i6l6s4l2g6h7@mansuetoventures.slack.com">
          Email us
        </a>{' '}
        and include the link you were trying to access so that we can fix it.
      </div>
    </div>
  );
}

export default DefaultError;
